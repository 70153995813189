.search {
  /* Display set to none - issue with Search crashing the android player - https://github.com/screencloud/screencloud-android-player/issues/473 */
  display: none;
  align-items: center;
  height: 100%;
  width: max-content;
}

.clearSearch {
  width: 13px;
  margin-right: 15px;
}

.clearSearch:hover {
  cursor: pointer;
}

.clearSearch:active,
:hover,
:focus {
  outline: 0;
}

.searchInput {
  background-color: transparent;
  outline: 0;
  border: none;
  font-size: 16px;
  margin: 0 15px 0 15px;
  width: 100%;
  height: 60px;
  color: #ffffff;
}

.searchInput::placeholder {
  opacity: 0.5;
}
