.grid {
  display: grid;
  height: 100%;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  max-height: 80%;
  align-content: flex-start;
}

@media (min-width: 768px) {
  .grid {
    grid-template-columns: repeat(auto-fit, minmax(25vw, 1fr));
  }
}

@media (min-width: 1024px) {
  .grid {
    grid-template-columns: repeat(auto-fit, minmax(20vw, 1fr));
  }
}

@media (min-width: 1200px) {
  .grid {
    grid-template-columns: repeat(auto-fit, minmax(15vw, 1fr));
  }
}

.itemTileWrapper {
  justify-content: center;
  display: flex;
  border-radius: 10px;
  flex: 1 0 21%;
  align-items: center;
  border: 1px solid transparent;
  flex-direction: column;
  margin-right: 6px;
  width: fit-content;
  cursor: pointer;
  height: fit-content;
  border: solid 2px transparent;
  width: 100%;
  height: 158px;
}

.itemLabel {
  margin-top: 8px;
  width: 160px;
  justify-content: center;
  display: flex;
  align-items: flex-end;
  height: 30px;
}

.itemLabel.active span {
  opacity: 1;
}

.itemLabel span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  height: 20px;
  font-size: 14px;
  opacity: 0.5;
}

.playlistHeader {
  font-size: 30px;
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.icon {
  vertical-align: middle;
  height: 24px;
  width: 24px;
  margin-right: 15px;
}
