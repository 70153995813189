.cardFlipInX {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: cardFlipInX;
  animation-name: cardFlipInX;
}

@keyframes cardFlipInX {
  from {
    -webkit-transform: perspective(1000px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(1000px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  to {
    -webkit-transform: perspective(1000px);
    transform: perspective(1000px);
  }
}

.cardFlipOutX {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: cardFlipOutX;
  animation-name: cardFlipOutX;
}

@keyframes cardFlipOutX {
  from {
    -webkit-transform: perspective(1000px);
    transform: perspective(1000px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  to {
    -webkit-transform: perspective(1000px) rotate3d(1, 0, 0, -90deg);
    transform: perspective(1000px) rotate3d(1, 0, 0, -90deg);
  }
}

.cardFlipInY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: cardFlipInY;
  animation-name: cardFlipInY;
}

@keyframes cardFlipInY {
  from {
    -webkit-transform: perspective(1000px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(1000px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    animation-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  to {
    -webkit-transform: perspective(1000px);
    transform: perspective(1000px);
  }
}

@-webkit-keyframes cardFlipInY {
  from {
    -webkit-transform: perspective(1000px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(1000px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    animation-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  to {
    -webkit-transform: perspective(1000px);
    transform: perspective(1000px);
  }
}

.cardFlipOutY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: cardFlipOutY;
  animation-name: cardFlipOutY;
}

@keyframes cardFlipOutY {
  from {
    -webkit-transform: perspective(1000px);
    transform: perspective(1000px);
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
  }

  to {
    -webkit-transform: perspective(1000px) rotate3d(0, 1, 0, -90deg);
    transform: perspective(1000px) rotate3d(0, 1, 0, -90deg);
  }
}

@-webkit-keyframes cardFlipOutY {
  from {
    -webkit-transform: perspective(1000px);
    transform: perspective(1000px);
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
  }

  to {
    -webkit-transform: perspective(1000px) rotate3d(0, 1, 0, -90deg);
    transform: perspective(1000px) rotate3d(0, 1, 0, -90deg);
  }
}

/* ------------------------- Rotate ------------------------- */
@-webkit-keyframes rotateInCounterclockwise {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes rotateInCounterclockwise {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.rotateInCounterclockwise {
  -webkit-animation-name: rotateInCounterclockwise;
  animation-name: rotateInCounterclockwise;
}

@-webkit-keyframes rotateOutCounterclockwise {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }
}

@keyframes rotateOutCounterclockwise {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }
}

.rotateOutCounterclockwise {
  -webkit-animation-name: rotateOutCounterclockwise;
  animation-name: rotateOutCounterclockwise;
}

@-webkit-keyframes rotateInClockwise {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes rotateInClockwise {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.rotateInClockwise {
  -webkit-animation-name: rotateInClockwise;
  animation-name: rotateInClockwise;
}

@-webkit-keyframes rotateOutClockwise {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }
}

@keyframes rotateOutClockwise {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }
}

.rotateOutClockwise {
  -webkit-animation-name: rotateOutClockwise;
  animation-name: rotateOutClockwise;
}

/* ------------------------- jackInTheBoxOut ------------------------- */
@-webkit-keyframes jackInTheBoxOut {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }

  70% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(0.1) rotate(30deg);
    transform: scale(0.1) rotate(30deg);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }
}

@keyframes jackInTheBoxOut {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }

  70% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(0.1) rotate(30deg);
    transform: scale(0.1) rotate(30deg);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
  }
}

.jackInTheBoxOut {
  -webkit-animation-name: jackInTheBoxOut;
  animation-name: jackInTheBoxOut;
}

/* ------------------------- Zoom ​(left to right ) ------------------------- */
@-webkit-keyframes customZoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-800%, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-800%, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
    animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.4, 0.4, 0.4) translate3d(-10%, 0, 0);
    transform: scale3d(0.4, 0.4, 0.4) translate3d(-10%, 0, 0);
  }
}

@keyframes customZoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-800%, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-800%, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
    animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.4, 0.4, 0.4) translate3d(-10%, 0, 0);
    transform: scale3d(0.4, 0.4, 0.4) translate3d(-10%, 0, 0);
  }
}

.customZoomInLeft {
  -webkit-animation-name: customZoomInLeft;
  animation-name: customZoomInLeft;
}

@-webkit-keyframes customZoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.4, 0.4, 0.4) translate3d(10%, 0, 0);
    transform: scale3d(0.4, 0.4, 0.4) translate3d(10%, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
    animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(800%, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(800%, 0, 0);
  }
}

@keyframes customZoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.4, 0.4, 0.4) translate3d(10%, 0, 0);
    transform: scale3d(0.4, 0.4, 0.4) translate3d(10%, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
    animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(800%, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(800%, 0, 0);
  }
}

.customZoomOutRight {
  -webkit-animation-name: customZoomOutRight;
  animation-name: customZoomOutRight;
}

/* ------------------------- Zoom ​(right to left ) ------------------------- */
@-webkit-keyframes customZoomInRight {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(800%, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(800%, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
    animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.4, 0.4, 0.4) translate3d(10%, 0, 0);
    transform: scale3d(0.4, 0.4, 0.4) translate3d(10%, 0, 0);
  }
}

@keyframes customZoomInRight {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(800%, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(800%, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
    animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.4, 0.4, 0.4) translate3d(10%, 0, 0);
    transform: scale3d(0.4, 0.4, 0.4) translate3d(10%, 0, 0);
  }
}

.customZoomInRight {
  -webkit-animation-name: customZoomInRight;
  animation-name: customZoomInRight;
}

@-webkit-keyframes customZoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.4, 0.4, 0.4) translate3d(-10%, 0, 0);
    transform: scale3d(0.4, 0.4, 0.4) translate3d(-10%, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
    animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-800%, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-800%, 0, 0);
  }
}

@keyframes customZoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.4, 0.4, 0.4) translate3d(-10%, 0, 0);
    transform: scale3d(0.4, 0.4, 0.4) translate3d(-10%, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
    animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-800%, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-800%, 0, 0);
  }
}

.customZoomOutLeft {
  -webkit-animation-name: customZoomOutLeft;
  animation-name: customZoomOutLeft;
}

/* ------------------------- Zoom up and down -------------------------*/
@-webkit-keyframes customZoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 800%, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 800%, 0);
    -webkit-animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
    animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.4, 0.4, 0.4) translate3d(0, 10% 0);
    transform: scale3d(0.4, 0.4, 0.4) translate3d(0, 10%, 0);
  }
}

@keyframes customZoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 800%, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 800%, 0);
    -webkit-animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
    animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.4, 0.4, 0.4) translate3d(0, 10% 0);
    transform: scale3d(0.4, 0.4, 0.4) translate3d(0, 10%, 0);
  }
}

.customZoomInUp {
  -webkit-animation-name: customZoomInUp;
  animation-name: customZoomInUp;
}

@-webkit-keyframes customZoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.4, 0.4, 0.4) translate3d(0, -10% 0);
    transform: scale3d(0.4, 0.4, 0.4) translate3d(0, -10%, 0);
    -webkit-animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
    animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -800%, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -800%, 0);
  }
}

@keyframes customZoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.4, 0.4, 0.4) translate3d(0, -10% 0);
    transform: scale3d(0.4, 0.4, 0.4) translate3d(0, -10%, 0);
    -webkit-animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
    animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -800%, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -800%, 0);
  }
}

.customZoomOutUp {
  -webkit-animation-name: customZoomOutUp;
  animation-name: customZoomOutUp;
}

@-webkit-keyframes customZoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -800%, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -800%, 0);
    -webkit-animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
    animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.4, 0.4, 0.4) translate3d(0, -10% 0);
    transform: scale3d(0.4, 0.4, 0.4) translate3d(0, -10%, 0);
  }
}

@keyframes customZoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -800%, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -800%, 0);
    -webkit-animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
    animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.4, 0.4, 0.4) translate3d(0, -10% 0);
    transform: scale3d(0.4, 0.4, 0.4) translate3d(0, -10%, 0);
  }
}

.customZoomInDown {
  -webkit-animation-name: customZoomInDown;
  animation-name: customZoomInDown;
}

@-webkit-keyframes customZoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.4, 0.4, 0.4) translate3d(0, 10% 0);
    transform: scale3d(0.4, 0.4, 0.4) translate3d(0, 10%, 0);
    -webkit-animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
    animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 800%, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 800%, 0);
  }
}

@keyframes customZoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.4, 0.4, 0.4) translate3d(0, 10% 0);
    transform: scale3d(0.4, 0.4, 0.4) translate3d(0, 10%, 0);
    -webkit-animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
    animation-timing-function: cubic-bezier(0.17, 0.67, 0.83, 0.67);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 800%, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 800%, 0);
  }
}

.customZoomOutDown {
  -webkit-animation-name: customZoomOutDown;
  animation-name: customZoomOutDown;
}

/* ------------------------- roll right to left------------------------- */
@-webkit-keyframes rollInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes rollInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.rollInRight {
  -webkit-animation-name: rollInRight;
  animation-name: rollInRight;
}

@-webkit-keyframes rollOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
  }
}

@keyframes rollOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
  }
}

.rollOutLeft {
  -webkit-animation-name: rollOutLeft;
  animation-name: rollOutLeft;
}

/* ------------------------- roll up------------------------- */
@-webkit-keyframes rollInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(0, 100%, 0) rotate3d(0, 0, 1, 120deg);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes rollInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(0, 100%, 0) rotate3d(0, 0, 1, 120deg);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.rollInUp {
  -webkit-animation-name: rollInUp;
  animation-name: rollInUp;
}

@-webkit-keyframes rollOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(0, -100%, 0) rotate3d(0, 0, 1, -120deg);
  }
}

@keyframes rollOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(0, -100%, 0) rotate3d(0, 0, 1, -120deg);
  }
}

.rollOutUp {
  -webkit-animation-name: rollOutUp;
  animation-name: rollOutUp;
}

/* ------------------------- roll down ------------------------- */
@-webkit-keyframes rollInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(0, -100%, 0) rotate3d(0, 0, 1, 120deg);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes rollInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(0, -100%, 0) rotate3d(0, 0, 1, 120deg);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.rollInDown {
  -webkit-animation-name: rollInDown;
  animation-name: rollInDown;
}

@-webkit-keyframes rollOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(0, 100%, 0) rotate3d(0, 0, 1, -120deg);
  }
}

@keyframes rollOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(0, 100%, 0) rotate3d(0, 0, 1, -120deg);
  }
}

.rollOutDown {
  -webkit-animation-name: rollOutDown;
  animation-name: rollOutDown;
}
