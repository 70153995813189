.playlistRowContainer {
  width: 100%;
}

.playlistName {
  display: inline-flex;
  align-items: center;
  padding: 7px;
  font-size: 14px;
  line-height: 17px;
  border-radius: 4px;
  cursor: pointer;
}

.playlistHeader {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.gridIcon {
  width: 18px;
  margin-right: 8px;
}

.itemTileWrapper {
  padding-top: 8px;
  width: 160px;
  height: 155px;
  overflow: visible;
  text-align: center;
  margin-right: 16px;
}

.itemLabel {
  margin-top: 8px;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: flex-end;
  height: 30px;
}

.itemLabel span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 20px;
  font-size: 14px;
  line-height: 17px;
  color: "#ffffff";
  opacity: 0.5;
}

.itemLabel.active {
  justify-content: space-between;
}

.itemLabel.active span {
  opacity: 1;
}
