.container {
  background: linear-gradient(
    0deg,
    rgb(219, 219, 219) 0%,
    rgb(255, 255, 255) 100%
  );
  border-radius: 5px;
  display: block;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  max-width: 55vw;
  overflow: hidden;
  padding: 25px 38px;
  position: absolute;
  z-index: 1;
}

.container .message {
  color: rgb(41, 41, 41);
  font-size: 2.4vw;
  font-weight: lighter;
  line-height: 1.5;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: pre-line;
}
