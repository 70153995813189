@import url("https://fonts.googleapis.com/css2?family=Sora:wght@500&display=swap");
.empty {
  scroll-snap-align: start;
  height: calc(100vh - 40px);
}

.container {
  scroll-snap-align: start;
  width: 100%;
  min-height: 100vh;
  opacity: 1;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.96) 63.88%,
    rgba(0, 0, 0, 0.87) 100%
  );
  mix-blend-mode: normal;
  display: flex;
  flex-direction: column;
  flex: 1;
  z-index: 30;
  box-sizing: border-box;
  transition: opacity 0.2s ease-in-out;
  position: relative;
}

.container.menuHidden {
  opacity: 0.8;
}

.stickySection {
  position: sticky;
  z-index: 5;
  top: 0px;
}

.menuScrollWrapper {
  height: calc(100vh - 90px);
  overflow: scroll;
}

.menuScrollWrapper::-webkit-scrollbar {
  display: none;
}

.sidePanel {
  flex: 0.2;
  background: rgba(0, 0, 0, 0.1);
  background-color: #1f2027;
  overflow-y: auto;
  min-width: 300px;
  padding-bottom: 40px;
}

.closeButtonContainer {
  right: 0;
  bottom: 0;
  position: absolute;
  width: 100%;
  pointer-events: none;
}

html.menuEnabled {
  scroll-snap-type: y mandatory;
  overflow: visible;
}

.grip {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000000;
}

.viewContainer {
  padding: 30px;
}

.viewContainer {
  padding: 30px;
}

.previousscreen {
  position: fixed;
  z-index: 4;
  top: 40%;
  left: 0;
  height: 180px;
  width: 90px;
  border-bottom-right-radius: 180px;
  border-top-right-radius: 180px;
  background: #000000;
  opacity: 0.2;
  font-size: large;
  display: flex;
  align-items: center;
}

.nextscreen {
  position: fixed;
  display: flex;
  z-index: 4;
  top: 40%;
  height: 180px;
  width: 90px;
  border-bottom-left-radius: 180px;
  border-top-left-radius: 180px;
  background: #000000;
  opacity: 0.2;
  right: 0%;
  align-items: center;
}

.vector {
  color: white;
  font-size: 50px;
}

.gripIcon {
  display: flex;
  align-items: center;
  height: 40%;
}

.iconPrevious {
  height: 50%;
}

.iconNext {
  height: 50%;
  margin-left: auto;
}

.castButton {
  position: fixed;
  top: 10%;
  right: 10%;
  background: #000000;
  opacity: 0.2;
  width: 48px;
  border: none;
  height: 32px;
  border-radius: 20px;
  color: white;
}
