.iconContainer {
  background-color: #343434;
  border-radius: 4px;
  height: 108px;
  width: 160px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.active {
  border-bottom: 2px solid white;
}

.iconContainer:hover {
  transform: scale(1.06);
  transition: transform 0.3s ease-out 0s;
}

.appIconContainer {
  background-color: #343434;
  border-radius: 4px;
  height: 108px;
  width: 160px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.appIconContainer:hover {
  transform: scale(1.06);
  transition: transform 0.3s ease-out 0s;
}

.appIconContainer img {
  width: 80px;
  height: 80px;
}

.imageTile {
  width: 160px;
  height: 108px;
  border-radius: 4px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #343434;
}
