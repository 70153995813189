.persiePlayer {
  width: 100%;
  height: 100%;
}

.persieVideo {
  width: 100%;
  height: 100%;
}

.videoFill {
  object-fit: cover;
}

.videoFit {
  object-fit: contain;
}
