.navbar {
  width: 100%;
  height: 60px;
  background-color: #000000;
  display: grid;
  grid-template-columns: 230px 1fr 150px;
  color: #ffffff;
  justify-items: flex-end;
}

.back {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  font-family: "Sora";
  font-size: 16px;
  min-width: fit-content;
  margin: auto;
}

.icon {
  width: 15px;
  padding-left: 24px;
}

.back span {
  margin-left: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.channelName {
  display: flex;
  width: 190px;
  height: 100%;
  align-items: center;
  font-weight: 700;
  font-family: "Sora";
  font-size: 16px;
}

.playAll {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: start;
  border-radius: 20px;
  cursor: pointer;
  height: 100%;
}

.playAllButton {
  border-radius: 20px;
  height: 32px;
  width: 80px;
  align-self: center;
  border: none;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  justify-self: flex-start;
  margin-left: 24px;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
}
