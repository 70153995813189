.root {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background: rgba(20, 20, 25, 0.9);
  border-left: 16px solid #fecf00;
  flex: 1;
  z-index: 31;
}

.content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  color: white;
  width: 80%;
  margin: auto;
  text-align: center;
  height: 100%;
}

h1 {
  font-size: 42.5px;
  padding: 32px;
  font-weight: 700;
  font-family: "Sora";
}

h2 {
  font-size: 27px;
  line-height: 1.5;
}

.playIcon {
  margin-left: 32px;
  margin-top: 32px;
}

.button:hover {
  cursor: pointer;
}

.button:hover,
:active,
:focus {
  outline: none;
}

.logo {
  max-width: 40vw;
  padding: 32px;
  height: 8%;
  margin-bottom: 32px;
}

mark {
  background-color: #fecf00;
  color: #19191b;
  padding: 16px 64px 16px 16px;
}
