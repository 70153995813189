.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  overflow: auto;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
}

.image {
  width: 100%;
  height: 100%;
}

.imageFit {
  object-fit: scale-down;
}

.imageFill {
  object-fit: cover;
}
