.qrcode {
  position: absolute;
  min-height: 164px;
  min-width: 164px;
  max-height: 500px;
}

.qrcode img {
  border-radius: 8px;
  object-fit: contain;
}

@media (orientation: portrait) {
  .qrcode {
    height: 12%;
  }
  .qrcode img {
    height: 100%;
    width: auto;
  }
}

@media (orientation: landscape) {
  .qrcode {
    width: 12%;
  }
  .qrcode img {
    width: 100%;
    height: auto;
  }
}
