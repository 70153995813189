/**
  * NB
  * - Only place global styles here. Styles for individual components should live in each component's folder.
  * - normalize.css is first imported via NPM in index.ts
  */

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap");

html,
body {
  background: transparent;
  color: #fff;
  font-family: Lato, Arial, Helvetica, sans-serif;
  overflow: hidden;
  scroll-snap-type: y mandatory;
}

#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

#root::-webkit-scrollbar,
html::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

video::-internal-media-controls-overlay-cast-button {
  display: none;
}

/* For interactive menu react-slider package to overwrite slider margin */
.slick-slider .slick-list .slick-track {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
