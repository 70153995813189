.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loaderTextContainer {
  margin-top: 40px;
}

.loaderText {
  font-size: 20px;
  color: #777777;
  opacity: 50%;
  text-align: center;
  font-family: Lato;
}
